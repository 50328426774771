<template>
  <v-menu
    v-model="menu"
    bottom
    left
    offset-y
    :close-on-content-click="false"
    content-class="menu-modal"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">mdi-dots-horizontal</v-icon>
    </template>

    <v-card class="d-flex flex-column mt-1 rounded overflow-hidden">
      <!-- Copy link -->
      <v-btn color="secondary" small class="justify-start" @click="copyLink">
        <v-icon left>mdi-content-copy</v-icon>
        {{ $t("common.copyLink") }}
      </v-btn>

      <!-- Edit -->
      <PostEditModal v-if="canEdit" :post="post" @close="menu = false" />

      <!-- Delete -->
      <v-btn
        v-if="canDelete"
        small
        color="secondary"
        class="justify-start"
        :loading="$loading('posts/delete') === post.id"
        @click="deletePost"
      >
        <v-icon left>mdi-trash-can</v-icon>
        {{ $t("common.delete") }}
      </v-btn>

      <!-- Report -->
      <ReportModal
        v-if="canReport"
        field="post_id"
        category="post"
        :id="post.id"
        @close="menu = false"
      />
    </v-card>
  </v-menu>
</template>

<script>
import PostEditModal from "./PostEditModal.vue";
import ReportModal from "@/components/app/reports/ReportModal.vue";

export default {
  components: { PostEditModal, ReportModal },

  props: {
    post: Object,
  },

  data: () => ({
    menu: false,
  }),

  computed: {
    canDelete() {
      return this.post.user.id === this.$user.id || this.$user.isAdmin();
    },

    canEdit() {
      return this.post.user.id === this.$user.id;
    },

    canReport() {
      return this.post.user.id !== this.$user.id;
    },
  },

  methods: {
    deletePost() {
      this.$confirm({
        text: this.$t("posts.deleteText"),
        dispatch: {
          action: "posts/delete",
          params: this.post.id,
        },
      })
        .then((res) => {
          if (res !== false) {
            this.$set(this.post, "is_deleted", true);
            this.$emit("deleted");
          }
        })
        .catch(() => {
          this.$toast.error("Error while deleting post.");
        });
    },

    copyLink() {
      const route = this.$router.resolve({
        name: "app.post",
        params: { id: this.post.id },
      });
      const link = window.location.origin + route.href;
      navigator.clipboard.writeText(link);

      this.$toast.success(this.$t("common.linkCopied"));
      this.menu = false;
    },
  },
};
</script>

<style></style>
