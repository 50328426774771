<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="secondary"
        small
        class="justify-start"
      >
        <v-icon left>mdi-pencil</v-icon>
        {{ $t("common.edit") }}
      </v-btn>
    </template>

    <v-card class="post-edit-modal">
      <v-card-title>
        <h3>
          {{ $t("posts.editPost") }}
        </h3>
        <v-spacer />
        <close-button @click="close" />
      </v-card-title>

      <v-divider />

      <v-card-text>
        <PostForm :editablePost="post" @updated="onUpdated" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import PostForm from "./PostForm.vue";

export default {
  components: { PostForm },

  props: {
    post: Object,
  },

  data: () => ({
    dialog: false,
  }),

  watch: {
    dialog(val) {
      val || this.$emit("close");
    },
  },

  methods: {
    onUpdated() {
      this.close();
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>

<style></style>
