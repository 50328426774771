<template>
  <v-hover v-slot="{ hover }">
    <v-btn
      v-if="group.is_pending"
      v-bind="$attrs"
      v-on="$listeners"
      :color="$attrs.color || 'primary'"
      :icon="icon"
      :loading="loading"
      @click="cancelJoin()"
    >
      <v-icon :left="!icon">
        {{ hover ? "mdi-cancel" : "mdi-timer-sand" }}
      </v-icon>

      <template v-if="!icon">
        {{
          hover
            ? $attrs["x-small"]
              ? $t("common.cancel")
              : $t("groups.cancelRequest")
            : $attrs["x-small"]
            ? $t("common.pending")
            : $t("groups.pendingRequest")
        }}
      </template>
    </v-btn>

    <v-btn
      v-else
      v-bind="$attrs"
      v-on="$listeners"
      :color="$attrs.color || 'primary'"
      :icon="icon"
      :loading="loading"
      @click="group.is_joined ? leaveGroup() : joinGroup()"
    >
      <v-icon :left="!icon">
        {{
          group.is_joined
            ? hover
              ? "mdi-minus-circle"
              : "mdi-check-bold"
            : "mdi-plus-circle"
        }}
      </v-icon>

      <template v-if="!icon">
        {{
          group.is_joined
            ? hover
              ? $t("groups.leave")
              : $t("groups.joined")
            : $t("groups.join")
        }}
      </template>
    </v-btn>
  </v-hover>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    group: Object,
    icon: Boolean,
  },

  computed: {
    loading() {
      return (
        this.$loading("groups/join") === this.group.id ||
        this.$loading("groups/leave") === this.group.id ||
        this.$loading("groups/cancelJoin") === this.group.id
      );
    },
  },

  methods: {
    joinGroup() {
      this.$store.dispatch("groups/join", this.group.id).then((res) => {
        if (res.is_joined) {
          this.$set(this.group, "is_joined", true);
          this.$set(
            this.group,
            "joined_users_count",
            this.group.joined_users_count + 1
          );
        } else if (res.is_pending) {
          this.$set(this.group, "is_pending", true);
        }
      });
    },

    leaveGroup() {
      this.$store.dispatch("groups/leave", this.group.id).then(() => {
        this.$set(this.group, "is_joined", false);
        this.$set(
          this.group,
          "joined_users_count",
          this.group.joined_users_count - 1
        );
      });
    },

    cancelJoin() {
      this.$store.dispatch("groups/cancelJoin", this.group.id).then(() => {
        this.$set(this.group, "is_pending", false);
      });
    },
  },
};
</script>

<style></style>
