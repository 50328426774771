var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"poll rounded pa-4 ocean-dark"},[_c('div',{staticClass:"d-flex flex-wrap align-center justify-space-between"},[_c('h3',{staticClass:"poll-title font-weight-bold font-size-16"},[_c('v-icon',{staticClass:"mr-2 pb-1"},[_vm._v("mdi-chart-box")]),_vm._v(" "+_vm._s(_vm.poll.title)+" ")],1),_c('small',{staticClass:"d-flex text--secondary"},[(_vm.hasVoted || _vm.hasEnded)?_c('UserListModal',{attrs:{"title":_vm.$t('polls.votes'),"action":"polls/fetchVotes","params":{ id: _vm.poll.id, params: {} }},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"btn-basic"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("polls.nVotes", { n: _vm.poll.total_votes }))+" ")])]}}],null,false,2171352446)}):_vm._e(),(_vm.hasVoted || _vm.hasEnded)?[_vm._v(" •")]:_vm._e(),(_vm.hasEnded)?[_vm._v(" "+_vm._s(_vm.$t("polls.ended"))+" ")]:[_vm._v(" "+_vm._s(_vm.$t("polls.timeLeft", { time: _vm.timeLeft }))+" ")]],2)]),_vm._l((_vm.poll.poll_options),function(option){return _c('div',{key:option.id,staticClass:"poll-option mt-3 border rounded pa-3 pos-rel d-flex align-center",class:{ 'border-primary': _vm.poll.voted_for === option.id }},[(_vm.canSeeResults)?_c('div',{staticClass:"bg-filler rounded",class:_vm.mostVoted && _vm.mostVoted.id === option.id ? 'primary' : 'secondary',staticStyle:{"position":"absolute","left":"0","height":"100%"},style:({ width: option.percentage + '%' }),attrs:{"value":option.percentage}}):_vm._e(),(!_vm.hasVoted && !_vm.hasEnded)?_c('div',{staticClass:"mr-3"},[(_vm.poll.voted_for === option.id)?_c('v-icon',[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"disabled":_vm.hasVoted || !!_vm.$loading('polls/vote')},on:{"click":function($event){return _vm.vote(option)}}},[_vm._v(" mdi-circle-outline ")])],1):_vm._e(),_c('div',{class:{
        'cursor-pointer': !_vm.hasVoted && !_vm.hasEnded,
        'font-size-14': _vm.$xs,
      },staticStyle:{"z-index":"2"},on:{"click":function($event){return _vm.vote(option)}}},[_vm._v(" "+_vm._s(option.text)+" ")]),_c('v-spacer'),(_vm.canSeeResults)?_c('small',{staticStyle:{"z-index":"2"}},[_vm._v(" "+_vm._s(option.percentage.toFixed())+"% ")]):_vm._e()],1)}),(_vm.hasVoted && _vm.poll.visibility_type === 'hidden')?_c('small',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.$t("polls.resultsHiddenNote"))+" ")]):_vm._e(),(
      _vm.hasVoted &&
      !_vm.canSeeResults &&
      _vm.poll.visibility_type === 'hidden-while-active'
    )?_c('small',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.$t("polls.resultsHiddenWhileActiveNote"))+" ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }