<template>
  <div class="comment-form">
    <!-- Form -->
    <v-form
      class="d-flex flex-wrap align-center"
      :class="{ 'form-valid': formValid }"
    >
      <!-- Avatar -->
      <v-avatar v-if="!isEdit" class="avatar mr-3" size="40">
        <v-img
          :src="$utils.userImage($user, '40x40')"
          width="40"
          height="40"
          :alt="$user.username"
        />
      </v-avatar>

      <!-- Textarea -->
      <v-textarea
        v-model="formData.content"
        class="textarea mt-0 pt-0"
        background-color="ocean-dark"
        ref="textarea"
        :maxlength="$config.posts.maxCommentLength"
        :placeholder="placeholder"
        auto-grow
        rows="1"
        rounded
        hide-details="auto"
        :append-icon="
          formValid ? (loading ? 'mdi-loading mdi-spin' : 'mdi-send') : null
        "
        :autofocus="!!editableComment"
        :disabled="loading"
        :hint="isEdit ? $t('posts.cancelEditHint') : null"
        @click:append="submit"
        @keydown.esc="cancel"
      />

      <!-- Emoji -->
      <EmojiMenu
        v-model="formData.content"
        iconOnly
        autoreplace
        @close="$refs.textarea.focus()"
      />

      <!-- Image -->
      <file-input
        v-model="formData.attachments"
        type="image"
        forceArray
        iconOnly
        :disabled="!!formData.attachments.length"
      />

      <!-- Video -->
      <file-input
        v-model="formData.attachments"
        type="video"
        iconSize="24"
        forceArray
        iconOnly
        :disabled="!!formData.attachments.length"
      />

      <!-- GIFs -->
      <GiphyPicker
        :disabled="!!formData.attachments.length"
        :icon="true"
        small
        @pick="({ attachment }) => formData.attachments.push(attachment)"
        @close="$refs.textarea.focus()"
      />
    </v-form>

    <!-- Attachments -->
    <PostAttachments
      v-if="formData.attachments.length"
      v-model="formData.attachments"
      :post="editableComment"
      editable
      isComment
      maxHeight="200px"
      class="mt-5"
    />
  </div>
</template>

<script>
import PostAttachments from "@/components/app/posts/PostAttachments.vue";
import EmojiMenu from "@/components/common/EmojiMenu.vue";
import GiphyPicker from "@/components/common/GiphyPicker.vue";
import { find as linkifyFind } from "linkifyjs";
import tribute from "@/plugins/tribute";

export default {
  components: { PostAttachments, EmojiMenu, GiphyPicker },

  props: {
    post: Object,
    parentComment: Object,
    editableComment: Object,
  },

  data: () => ({
    formData: {
      attachments: [],
      content: "",
    },
    loading: false,
  }),

  computed: {
    formValid() {
      return !!this.formData.content || this.formData.attachments.length;
    },

    isEdit() {
      return !!this.editableComment;
    },

    placeholder() {
      if (this.isEdit) {
        return null;
      }

      return this.parentComment
        ? this.$t("posts.writeReplyTo", {
            username: this.parentComment.user.username,
          })
        : this.$t("posts.writeComment");
    },
  },

  created() {
    this.initForm();
  },

  mounted() {
    tribute.attach(this.$refs.textarea.$refs.input);
  },

  methods: {
    initForm() {
      if (this.isEdit) {
        this.formData.attachments = [...this.editableComment.attachments];
        this.formData.content = this.editableComment.content;
      }
    },

    submit() {
      this.isEdit ? this.updateComment() : this.createComment();
    },

    createComment() {
      this.loading = true;
      this.$store
        .dispatch("comments/create", this.getPayload())
        .then((comment) => {
          this.resetForm();
          this.$emit("created", comment);
        })
        .catch(() => {
          this.$toast.error("Error while creating comment.");
        })
        .finally(() => {
          this.loading = false;
        });
    },

    updateComment() {
      this.loading = true;
      this.$store
        .dispatch("comments/update", this.getPayload())
        .then((comment) => {
          for (const key in this.editableComment) {
            if (
              Object.hasOwnProperty.call(this.editableComment, key) &&
              comment[key] !== undefined
            ) {
              this.$set(this.editableComment, key, comment[key]);
            }
          }

          this.$emit("updated", this.editableComment);
        })
        .catch(() => {
          this.$toast.error("Error while updating comment.");
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getPayload() {
      const payload = {
        ...this.formData,
        mentions: this.getMentions(),
      };

      if (!this.isEdit) {
        payload.post_id = this.post.id;

        if (this.parentComment) {
          payload.parent_id = this.parentComment.id;
        }
      }

      if (!payload.content) {
        delete payload.content;
      }

      if (!payload.attachments.length) {
        delete payload.attachments;
      } else if (
        payload.attachments[0].type === "gif" &&
        !payload.attachments[0].id
      ) {
        payload.gif = payload.attachments[0];
        delete payload.attachments;
      } else {
        payload.attachments = payload.attachments.filter((i) => !i.id);
      }

      if (this.isEdit) {
        return {
          id: this.editableComment.id,
          payload,
        };
      }

      return payload;
    },

    getMentions() {
      return linkifyFind(this.formData.content || "", "mention").map((i) =>
        i.value.substring(1)
      );
    },

    cancel() {
      this.$emit("cancel");
    },

    resetForm() {
      this.formData.attachments = [];
      this.formData.content = "";
    },
  },
};
</script>

<style lang="scss" scoped>
:deep {
  .textarea {
    .v-text-field__details {
      padding-left: 24px;
    }
  }
}
</style>
