<template>
  <v-menu
    class="post-like-action"
    open-on-hover
    top
    offset-y
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="primary"
        icon
        :loading="$loading('posts/toggleLike') === post.id"
        @click="like('heart')"
      >
        <v-icon>
          {{ post.liked ? "mdi-heart" : "mdi-heart-outline" }}
        </v-icon>
      </v-btn>
    </template>

    <div class="reactions py-1 px-2 ocean-dark border rounded-lg">
      <v-btn
        v-for="reaction in availableReactions"
        :key="reaction.type"
        icon
        :class="{ primary: post.liked_type === reaction.type }"
        :loading="loading === reaction.type"
        @click="like(reaction.type)"
      >
        {{ reaction.emoji }}
      </v-btn>
    </div>
  </v-menu>
</template>

<script>
import reactionsMixin from "@/mixins/reactions.mixin";

export default {
  mixins: [reactionsMixin],

  props: {
    post: Object,
  },

  data: () => ({
    loading: null,
  }),

  methods: {
    like(type = "like") {
      this.loading = type;
      this.$store
        .dispatch("posts/toggleLike", { id: this.post.id, type })
        .then((post) => {
          this.$set(this.post, "liked", post.liked);
          this.$set(this.post, "liked_type", post.liked_type);
          this.$set(this.post, "likes_count", post.likes_count);
        })
        .catch(() => {
          this.$toast.error("Error while liking post.");
        })
        .finally(() => {
          this.loading = null;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep {
  .reactions {
    .v-btn__content {
      font-size: 25px !important;
      line-height: 0;
    }
  }
}
</style>
