<template>
  <v-menu
    v-model="menu"
    bottom
    left
    offset-y
    :close-on-content-click="false"
    content-class="menu-modal"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">mdi-dots-horizontal</v-icon>
    </template>

    <v-card class="d-flex flex-column mt-1 rounded overflow-hidden">
      <!-- Edit -->
      <v-btn
        v-if="canEdit"
        small
        color="secondary"
        class="justify-start"
        @click="
          $emit('click:edit');
          menu = false;
        "
      >
        <v-icon left>mdi-pencil</v-icon>
        {{ $t("common.edit") }}
      </v-btn>

      <!-- Delete -->
      <v-btn
        v-if="canDelete"
        small
        color="secondary"
        class="justify-start"
        :loading="$loading('comments/delete') === comment.id"
        @click="deleteComment"
      >
        <v-icon left>mdi-trash-can</v-icon>
        {{ $t("common.delete") }}
      </v-btn>

      <!-- Report -->
      <ReportModal
        v-if="canReport"
        field="comment_id"
        category="comment"
        :id="comment.id"
        @close="menu = false"
      />
    </v-card>
  </v-menu>
</template>

<script>
import ReportModal from "@/components/app/reports/ReportModal.vue";

export default {
  components: { ReportModal },

  props: {
    comment: Object,
  },

  data: () => ({
    menu: false,
  }),

  computed: {
    canDelete() {
      return this.comment.user.id === this.$user.id || this.$user.isAdmin();
    },

    canEdit() {
      return this.comment.user.id === this.$user.id;
    },

    canReport() {
      return this.comment.user.id !== this.$user.id;
    },
  },

  methods: {
    async deleteComment() {
      if (!(await this.$confirm())) {
        return;
      }

      this.$store
        .dispatch("comments/delete", this.comment.id)
        .then(() => {
          this.$set(this.comment, "is_deleted", true);
          this.$emit("deleted", this.comment);
        })
        .catch(() => {
          this.$toast.error("Error while deleting comment.");
        });
    },
  },
};
</script>

<style></style>
