import router from "@/router";

import linkifyHtml from "linkify-html";
import "linkify-plugin-mention";
import "linkify-plugin-hashtag";

const options = {
  format: (value, type) => {
    switch (type) {
      case "mention":
        break;
    }

    return value;
  },

  tagName: {
    mention: () => "a",
    hashtag: () => "a",
  },

  formatHref: {
    mention: (href) => "/user" + href,
    hashtag: (href) => "/search/posts?q=" + href,
  },

  attributes: (href, type) => {
    switch (type) {
      case "url":
        return {
          class: "link",
          target: "_blank",
        };

      case "mention":
        return {
          class: "mention",
          "data-username": href.slice(1),
        };

      case "hashtag":
        return {
          class: "hashtag",
          "data-hashtag": href.slice(1),
        };

      default:
        return {};
    }
  },
};

export function setRouting(ref) {
  if (!ref) {
    return;
  }

  const mentions = Array.from(ref.querySelectorAll(".mention"));
  mentions.forEach((el) => {
    el.onclick = (e) => {
      e.preventDefault();

      router.push({
        name: "app.user",
        params: {
          id: el.getAttribute("data-username"),
        },
      });
    };
  });

  const hashtags = Array.from(ref.querySelectorAll(".hashtag"));
  hashtags.forEach((el) => {
    el.onclick = (e) => {
      e.preventDefault();

      router.push({
        name: "app.search",
        params: {
          index: "posts",
        },
        query: {
          q: "#" + el.getAttribute("data-hashtag"),
        },
      });
    };
  });
}

export default function (string = "") {
  return linkifyHtml(string || "", options);
}
