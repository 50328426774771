<template>
  <div class="comment-feed">
    <!-- Load more -->
    <div
      v-if="unloadedCount"
      class="load-more mb-4 cursor-pointer"
      @click="fetchData"
    >
      <template v-if="loading">
        {{ $t("common.loading") }}
      </template>
      <template v-else>
        {{ $t("posts.loadMoreComments") }}
        ({{ unloadedCount }})
      </template>
    </div>

    <!-- Comments -->
    <Comment
      v-for="comment in comments"
      :key="comment.id"
      :comment="comment"
      :post="post"
      @deleted="onCommentDeleted"
    />

    <!-- Form -->
    <CommentForm
      :post="post"
      :parentComment="parentComment"
      @created="onCommentCreated"
    />
  </div>
</template>

<script>
import { unshiftUnique } from "@/utils/array";
import Comment from "./Comment.vue";
import CommentForm from "./CommentForm.vue";

export default {
  components: { Comment, CommentForm },

  props: {
    post: Object,
    parentComment: Object,
  },

  data: () => ({
    comments: [],
    meta: {
      current_page: 0,
    },
    loading: false,
  }),

  computed: {
    unloadedCount() {
      return this.parentComment
        ? this.parentComment.child_count - this.comments.length
        : this.post.comment_root_count - this.comments.length;
    },
  },

  created() {
    if (this.parentComment) {
      this.fetchData();
    } else {
      this.comments.push(...this.post.comments.reverse());
    }
  },

  methods: {
    fetchData() {
      if (this.loading) {
        return;
      }

      const params = {
        "exact_search[post_id]": this.post.id,
        page: this.meta.current_page + 1,
        per_page: this.parentComment
          ? this.$config.posts.commentRepliesPerPage
          : this.$config.posts.commentsPerPage,
      };

      if (this.parentComment) {
        params["exact_search[parent_id]"] = this.parentComment.id;
      } else {
        params["root_comments"] = "true";
      }

      this.loading = true;
      this.$store
        .dispatch("comments/fetch", params)
        .then((res) => {
          unshiftUnique(this.comments, "id", ...res.data);
          this.meta = res.meta;

          if (this.parentComment) {
            this.$set(this.parentComment, "child_count", res.meta.total);
          } else {
            this.$set(this.post, "comment_root_count", res.meta.total);
          }
        })
        .catch(() => {
          this.$toast.error("Error while fetching comments.");
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onCommentCreated(comment) {
      this.$set(this.post, "comment_count", this.post.comment_count + 1);
      this.comments.push(comment);

      if (this.parentComment) {
        this.$set(
          this.parentComment,
          "child_count",
          this.parentComment.child_count + 1
        );
      } else {
        this.$set(
          this.post,
          "comment_root_count",
          this.post.comment_root_count + 1
        );
        this.post.comments.unshift(comment);
      }
    },

    onCommentDeleted(comment) {
      this.comments = this.comments.filter((c) => c.id !== comment.id);
      this.$set(this.post, "comment_count", this.post.comment_count - 1);

      if (this.parentComment) {
        this.$set(
          this.parentComment,
          "child_count",
          this.parentComment.child_count - 1
        );
      } else {
        this.$set(
          this.post,
          "comment_root_count",
          this.post.comment_root_count - 1
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:deep {
  .comment {
    margin-bottom: 12px;
  }
}
</style>
