<template>
  <div class="post-actions d-flex">
    <!-- Likes -->
    <div class="post-action likes">
      <PostLikeAction :post="post" />

      <UserListTabModal
        :tabs="getTabsForReactionsModal()"
        @data:fetched="({ like_types }) => $set(post, 'like_types', like_types)"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-hover v-slot="{ hover }">
            <span class="counter" v-on="on" v-bind="attrs">
              <template v-if="hover">
                {{ $tc("posts.nLikes", Number(post.likes_count)) }}
              </template>
              <template v-else>{{ Number(post.likes_count) }}</template>
            </span>
          </v-hover>
        </template>

        <template v-slot:actions="{ user }">
          {{ reactionTypeToEmoji(user.like_type) }}
        </template>
      </UserListTabModal>
    </div>

    <!-- Comments -->
    <div class="post-action comments">
      <v-btn color="primary" icon @click="comment">
        <v-icon> mdi-message-outline </v-icon>
      </v-btn>

      <span class="counter">
        {{ Number(post.comment_count) }}
      </span>
    </div>

    <!-- Shares -->
    <div v-if="!post.original_post" class="post-action shares">
      <PostShareModal :post="post" />

      <span class="counter">
        {{ Number(post.shared_count) }}
      </span>
    </div>
  </div>
</template>

<script>
import reactionsMixin from "@/mixins/reactions.mixin";

import PostLikeAction from "./PostLikeAction.vue";
import PostShareModal from "./PostShareModal.vue";
import UserListTabModal from "@/components/app/users/UserListTabModal.vue";

export default {
  mixins: [reactionsMixin],

  components: { PostLikeAction, PostShareModal, UserListTabModal },

  props: {
    post: Object,
  },

  methods: {
    comment() {
      this.$emit("click:comment");
    },

    getTabsForReactionsModal() {
      const tabs = [
        {
          title: this.$t("common.all"),
          action: "posts/fetchLikes",
          params: { id: this.post.id, params: {} },
        },
      ];

      if (this.post.like_types) {
        for (const [key, value] of Object.entries(this.post.like_types)) {
          if (!key) {
            continue;
          }

          tabs.push({
            title: this.reactionTypeToEmoji(key) + " " + value,
            action: "posts/fetchLikes",
            params: {
              id: this.post.id,
              params: { "exact_search[like_type]": key },
            },
          });
        }
      }

      // Show tab for every reaction
      // this.availableReactions.forEach((r) => {
      //   tabs.push({
      //     title: r.emoji,
      //     action: "posts/fetchLikes",
      //     params: {
      //       "exact_search[post_id]": this.post.id,
      //       "exact_search[like_type]": r.type,
      //     },
      //     btnAttrs: { fab: true },
      //   });
      // });

      return tabs;
    },
  },
};
</script>

<style lang="scss" scoped>
.post-actions {
  margin-left: -8px;
}

.post-action {
  margin-right: 5px;

  .counter {
    display: inline-block;
    font-weight: 500;
    color: $gray-light;
  }
}
</style>
