export default {
  data: () => ({
    availableReactions: [
      { type: "heart", emoji: "💚" },
      { type: "like", emoji: "👍️" },
      { type: "laughter", emoji: "😆" },
      { type: "angry", emoji: "😠" },
      { type: "crying", emoji: "😢" },
    ],
  }),

  methods: {
    reactionTypeToEmoji(type) {
      return this.availableReactions.find((r) => r.type === type)?.emoji || "";
    },
  },
};
