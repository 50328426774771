<template>
  <div
    v-if="!comment.is_deleted"
    :id="`comment-${comment.id}`"
    class="comment d-flex"
    :class="{ 'is-edit': edit }"
  >
    <!-- Left -->
    <div class="comment-left">
      <!-- Avatar -->
      <UserHoverCard :data="comment.user">
        <router-link
          :to="{ name: 'app.user', params: { id: comment.user.username } }"
        >
          <v-avatar class="avatar mr-3" size="40">
            <v-img
              :src="$utils.userImage(comment.user, '40x40')"
              width="40"
              height="40"
              :alt="comment.user.username"
            />
          </v-avatar>
        </router-link>
      </UserHoverCard>
    </div>

    <!-- Right -->
    <div v-if="!edit" class="comment-right flex-grow-1">
      <!-- Card -->
      <div
        class="ocean-dark rounded-lg pa-3 pb-1"
        :class="{ fresh: comment.fresh }"
      >
        <!-- Meta -->
        <div class="meta d-flex">
          <div class="flex-grow-1">
            <!-- Username -->
            <div class="username font-weight-bold">
              {{ comment.user.username }}
            </div>

            <!-- Date -->
            <div
              class="date text--secondary font-size-12"
              :title="$utils.formatDatetime(comment.created_at)"
            >
              <datetime :value="comment.created_at" fromNow />

              <span
                v-if="comment.edited_at"
                :title="
                  $t('common.editedAt', {
                    date: $utils.formatDatetime(comment.edited_at),
                  })
                "
              >
                &bull; {{ $t("common.edited").toLowerCase() }}
              </span>
            </div>
          </div>

          <!-- Menu -->
          <div>
            <CommentMenu
              :comment="comment"
              @click:edit="edit = true"
              @deleted="(e) => $emit('deleted', e)"
            />
          </div>
        </div>

        <!-- Content -->
        <p
          ref="content"
          class="content linkified mt-3 mb-0 text-formatted"
          v-html="linkifiedContent"
        />

        <!-- Attachments -->
        <PostAttachments
          :post="comment"
          isComment
          maxHeight="200px"
          class="mt-3"
        />

        <!-- Actions -->
        <CommentActions
          :comment="comment"
          @reply="showReplies = true"
          class="mt-1"
        />
      </div>

      <!-- Replies -->
      <CommentFeed
        v-if="showReplies"
        :post="post"
        :parentComment="comment"
        class="mt-3 mb-5"
      />
    </div>

    <!-- Edit comment -->
    <CommentForm
      v-else
      class="flex-grow-1"
      :editableComment="comment"
      @cancel="edit = false"
      @updated="edit = false"
    />
  </div>
</template>

<script>
import linkify, { setRouting } from "@/plugins/linkify";
import CommentActions from "./CommentActions.vue";
import CommentForm from "./CommentForm.vue";
import CommentMenu from "./CommentMenu.vue";
import UserHoverCard from "@/components/app/users/UserHoverCard.vue";
import PostAttachments from "@/components/app/posts/PostAttachments.vue";

export default {
  components: {
    CommentActions,
    CommentFeed: () => import("./CommentFeed.vue"),
    CommentForm,
    CommentMenu,
    UserHoverCard,
    PostAttachments,
  },

  props: {
    comment: Object,
    post: Object,
  },

  data: () => ({
    edit: false,
    showReplies: false,
  }),

  computed: {
    linkifiedContent() {
      return linkify(this.comment.content);
    },
  },

  created() {
    if (!this.comment.user) {
      this.$set(this.comment, "user", this.$user);
    }

    if (this.comment.fresh) {
      setTimeout(() => {
        this.$set(this.comment, "fresh", false);
      }, 3000);
    }
  },

  mounted() {
    setRouting(this.$refs.content);
  },
};
</script>

<style lang="scss" scoped>
.fresh {
  border: 1px solid var(--v-primary-base);
}

.is-edit {
  border-radius: $border-radius-root * 2;
  padding: 5px;
  background-color: var(--v-secondary-base);
}
</style>
