<template>
  <div class="suggestions-widget widget">
    <label class="label">
      {{ $t("common.suggestions") }}
    </label>

    <div class="card pa">
      <!-- Loader -->
      <spinner v-if="$loading('feed/fetchSuggestions')" center />

      <!-- List -->
      <template v-else>
        <div class="list-item" v-for="(item, i) in items" :key="i">
          <UserRow v-if="item.username" :user="item" />

          <GroupRow v-else-if="item.name" :group="item" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import UserRow from "@/components/app/users/UserRow.vue";
import GroupRow from "@/components/app/groups/GroupRow.vue";

export default {
  components: { UserRow, GroupRow },

  data: () => ({
    items: [],
  }),

  created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.$store
        .dispatch("feed/fetchSuggestions")
        .then((data) => {
          this.items = data.data;
        })
        .catch(() => {
          this.$toast.error("Error while getting suggestions.");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
