<template>
  <div class="comment-actions d-flex">
    <!-- Likes -->
    <div class="comment-action likes">
      <v-btn
        color="primary"
        icon
        small
        :loading="$loading('comments/toggleLike') === comment.id"
        @click="like"
      >
        <v-icon>
          {{ comment.liked ? "mdi-heart" : "mdi-heart-outline" }}
        </v-icon>
      </v-btn>

      <UserListModal
        :title="$t('posts.likes')"
        action="comments/fetchLikes"
        :params="{
          'exact_search[comment_id]': comment.id,
        }"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-hover v-slot="{ hover }">
            <span class="counter" v-on="on" v-bind="attrs">
              <template v-if="hover">
                {{ $tc("posts.nLikes", Number(comment.likes_count)) }}
              </template>
              <template v-else>{{ Number(comment.likes_count) }}</template>
            </span>
          </v-hover>
        </template>
      </UserListModal>
    </div>

    <!-- Replies -->
    <div v-if="canReply" class="comment-action replies">
      <v-btn color="primary" icon small @click="$emit('reply')">
        <v-icon>mdi-message-reply-outline</v-icon>
      </v-btn>

      <span class="counter">
        {{ Number(comment.child_count) }}
      </span>
    </div>
  </div>
</template>

<script>
import UserListModal from "@/components/app/users/UserListModal.vue";

export default {
  components: { UserListModal },

  props: {
    comment: Object,
  },

  computed: {
    canReply() {
      return !this.comment.parent_id;
    },
  },

  methods: {
    like() {
      this.$store
        .dispatch("comments/toggleLike", this.comment.id)
        .then(() => {
          this.$set(this.comment, "liked", !this.comment.liked);
          this.$set(
            this.comment,
            "likes_count",
            this.comment.liked
              ? Number(this.comment.likes_count) + 1
              : Number(this.comment.likes_count) - 1
          );
        })
        .catch(() => {
          this.$toast.error("Error while liking comment.");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-actions {
  margin-left: -8px;
}

.comment-action {
  margin-right: 5px;

  .counter {
    display: inline-block;
    font-weight: 500;
    color: $gray-light;
  }
}
</style>
