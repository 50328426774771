<template>
  <div class="poll-duration-picker">
    <!-- Inputs -->
    <div class="d-flex mt-2">
      <!-- Days -->
      <v-select
        v-model="picker.days"
        :label="$t('polls.days')"
        :items="days"
        outlined
        hide-details
        dense
        menu-props="offsetY"
      />

      <!-- Hours -->
      <v-select
        v-model="picker.hours"
        :label="$t('polls.hours')"
        :items="hours"
        outlined
        hide-details
        dense
        menu-props="offsetY"
        class="mx-3 mx-sm-5"
      />

      <!-- Minutes -->
      <v-select
        v-model="picker.minutes"
        :label="$t('polls.minutes')"
        :items="minutes"
        outlined
        hide-details
        dense
        menu-props="offsetY"
      />
    </div>

    <!-- Label -->
    <div class="d-flex">
      <v-spacer />
      <small class="text--secondary">
        {{ $t("common.endsOn", { date: endsAt }) }}
      </small>
    </div>
  </div>
</template>

<script>
import config from "@/config/config";

const MIN_DURATION = config.polls.minDuration;

export default {
  props: {
    poll: Object,
  },

  data: () => ({
    picker: {
      days: 1,
      hours: 0,
      minutes: 0,
    },
  }),

  computed: {
    days() {
      return Array.from(Array(11).keys());
    },

    hours() {
      return Array.from(Array(24).keys());
    },

    minutes() {
      const minutes = Array.from(Array(60).keys());

      return this.picker.days === 0 && this.picker.hours === 0
        ? minutes.slice(MIN_DURATION)
        : minutes;
    },

    endsAt() {
      const dayjs = this.poll.ends_at
        ? this.$utils.dayjs(this.poll.ends_at)
        : this.$utils.dayjs().add(1, "day");

      return dayjs.format("MMM D, YYYY HH:mm");
    },
  },

  watch: {
    picker: {
      handler: "onChanged",
      deep: true,
    },
    "picker.days": "onDaysChanged",
    "picker.hours": "onHoursChanged",
  },

  created() {
    this.poll.ends_at || this.$nextTick(() => this.onChanged(this.picker));
  },

  methods: {
    onChanged(picker) {
      const endDate = this.$utils
        .dayjs()
        .add(picker.days, "day")
        .add(picker.hours, "hour")
        .add(picker.minutes, "minute")
        .format("YYYY-MM-DD HH:mm");

      this.$set(this.poll, "ends_at", endDate);
    },

    onDaysChanged(days) {
      if (days === 0 && this.picker.hours === 0 && this.picker.minutes === 0) {
        this.picker.hours = 1;
      } else if (
        days === 0 &&
        this.picker.hours === 0 &&
        this.picker.minutes < MIN_DURATION
      ) {
        this.picker.minutes = MIN_DURATION;
      }
    },

    onHoursChanged(hours) {
      if (hours === 0 && this.picker.days === 0 && this.picker.minutes === 0) {
        this.picker.minutes = MIN_DURATION;
      } else if (
        hours === 0 &&
        this.picker.days === 0 &&
        this.picker.minutes < MIN_DURATION
      ) {
        this.picker.minutes = MIN_DURATION;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.poll-duration-picker {
  .v-select {
    max-width: auto;
  }
}
</style>
