<template>
  <div class="poll rounded pa-4 ocean-dark">
    <div class="d-flex flex-wrap align-center justify-space-between">
      <!-- Title -->
      <h3 class="poll-title font-weight-bold font-size-16">
        <v-icon class="mr-2 pb-1">mdi-chart-box</v-icon>
        {{ poll.title }}
      </h3>

      <small class="d-flex text--secondary">
        <!-- Poll votes modal -->
        <UserListModal
          v-if="hasVoted || hasEnded"
          :title="$t('polls.votes')"
          action="polls/fetchVotes"
          :params="{ id: poll.id, params: {} }"
        >
          <template v-slot:activator="{ on, attrs }">
            <span class="btn-basic" v-bind="attrs" v-on="on">
              {{ $t("polls.nVotes", { n: poll.total_votes }) }}
            </span>
          </template>
        </UserListModal>

        <template v-if="hasVoted || hasEnded">&nbsp;&bull;</template>

        <!-- Poll time left -->
        <template v-if="hasEnded">
          {{ $t("polls.ended") }}
        </template>
        <template v-else>
          {{ $t("polls.timeLeft", { time: timeLeft }) }}
        </template>
      </small>
    </div>

    <!-- Options -->
    <div
      v-for="option in poll.poll_options"
      :key="option.id"
      class="poll-option mt-3 border rounded pa-3 pos-rel d-flex align-center"
      :class="{ 'border-primary': poll.voted_for === option.id }"
    >
      <!-- Background filler -->
      <div
        v-if="canSeeResults"
        :value="option.percentage"
        class="bg-filler rounded"
        :class="
          mostVoted && mostVoted.id === option.id ? 'primary' : 'secondary'
        "
        style="position: absolute; left: 0; height: 100%"
        :style="{ width: option.percentage + '%' }"
      ></div>

      <!-- Icon / Button -->
      <div v-if="!hasVoted && !hasEnded" class="mr-3">
        <!-- Voted -->
        <v-icon v-if="poll.voted_for === option.id">mdi-check-circle</v-icon>

        <!-- Not voted -->
        <v-icon
          v-else
          :disabled="hasVoted || !!$loading('polls/vote')"
          @click="vote(option)"
        >
          mdi-circle-outline
        </v-icon>
      </div>

      <!-- Text -->
      <div
        :class="{
          'cursor-pointer': !hasVoted && !hasEnded,
          'font-size-14': $xs,
        }"
        @click="vote(option)"
        style="z-index: 2"
      >
        {{ option.text }}
      </div>

      <v-spacer />

      <!-- Total votes -->
      <small v-if="canSeeResults" style="z-index: 2">
        <!-- {{ $t("polls.nVotes", { n: option.total_votes }) }} -->
        {{ option.percentage.toFixed() }}%
      </small>
    </div>

    <!-- Notes -->
    <small
      v-if="hasVoted && poll.visibility_type === 'hidden'"
      class="text--secondary"
    >
      {{ $t("polls.resultsHiddenNote") }}
    </small>

    <small
      v-if="
        hasVoted &&
        !canSeeResults &&
        poll.visibility_type === 'hidden-while-active'
      "
      class="text--secondary"
    >
      {{ $t("polls.resultsHiddenWhileActiveNote") }}
    </small>
  </div>
</template>

<script>
import UserListModal from "@/components/app/users/UserListModal.vue";

export default {
  components: { UserListModal },

  props: {
    poll: Object,
  },

  computed: {
    canSeeResults() {
      switch (this.poll.visibility_type) {
        case "public":
          return this.hasVoted || this.hasEnded;

        case "hidden":
          return this.poll.is_creator;

        case "hidden-while-active":
          return this.hasEnded || this.poll.is_creator;

        default:
          return true;
      }
    },

    hasEnded() {
      return this.$utils.dayjs(this.poll.ends_at).isBefore(this.$utils.dayjs());
    },

    hasVoted() {
      return !!this.poll.voted_for;
    },

    timeLeft() {
      return this.$utils.dayjs(this.poll.ends_at).toNow(true);
    },

    mostVoted() {
      let result = this.poll.poll_options[0];
      let isOnly = true;

      this.poll.poll_options.forEach((option, i) => {
        if (i === 0) {
          return;
        }

        if (option.total_votes > result.total_votes) {
          result = option;
          isOnly = true;
        } else if (option.total_votes === result.total_votes) {
          isOnly = false;
        }
      });

      return isOnly ? result : null;
    },
  },

  watch: {
    poll: {
      handler: "calculatePercentages",
      deep: true,
    },
  },

  created() {
    this.calculatePercentages();
  },

  methods: {
    vote(option) {
      if (this.hasVoted || this.hasEnded) {
        return;
      }

      this.$store
        .dispatch("polls/vote", option.id)
        .then((poll) => {
          this.$set(this.poll, "voted_for", option.id);
          this.$set(this.poll, "total_votes", poll.total_votes);
          this.$set(this.poll, "poll_options", poll.poll_options);
          this.$toast.info(this.$t("polls.thanks"));
        })
        .catch(() => this.$toast.error("Error while voting on poll."));
    },

    calculatePercentages() {
      this.poll.poll_options.forEach((option) => {
        const isZero = this.poll.total_votes === 0 || option.total_votes === 0;
        option.percentage = isZero
          ? 0
          : (option.total_votes / this.poll.total_votes) * 100;
      });
    },
  },
};
</script>

<style></style>
