<template>
  <div class="post-form d-flex">
    <!-- Avatar -->
    <v-avatar v-if="$vuetify.breakpoint.mdAndUp" class="avatar mr-3" size="50">
      <v-img
        :src="$utils.userImage($user, '50x50')"
        width="50"
        height="50"
        :alt="$user.username"
      />
    </v-avatar>

    <!-- Form -->
    <v-form class="form flex-grow-1" ref="form" @submit.prevent="onSubmit">
      <!-- Textarea -->
      <v-textarea
        v-model="formData.content"
        ref="textarea"
        class="textarea pt-0"
        background-color="ocean-dark"
        :maxlength="$config.posts.maxContentLength"
        :placeholder="$t('posts.createPlaceholder')"
        auto-grow
        rounded
        rows="1"
        hide-details
      >
      </v-textarea>

      <!-- Attachments -->
      <PostAttachments
        v-if="formData.attachments.length"
        v-model="formData.attachments"
        :post="editablePost"
        editable
        class="mt-5"
      />

      <!-- Poll form -->
      <PollForm
        v-if="pollSelected"
        v-model="formData.poll"
        :isValid.sync="pollValid"
        class="my-8"
        @remove="onPollRemove"
      />

      <!-- ApiResponse -->
      <api-response :response="formResponse" class="mt-5" />

      <!-- Buttons -->
      <div class="buttons d-flex mt-5 flex-wrap">
        <template v-if="!disableAttachments">
          <!-- Emoji -->
          <EmojiMenu
            v-model="formData.content"
            autoreplace
            @close="$refs.textarea.focus()"
          />

          <!-- Image -->
          <file-input
            type="image"
            forceArray
            :disabled="!!formData.attachments.length"
            @input="addAttachments"
          />

          <!-- Video -->
          <file-input
            type="video"
            iconSize="24"
            forceArray
            :disabled="!!formData.attachments.length"
            @input="addAttachments"
          />

          <!-- GIFs -->
          <GiphyPicker
            :disabled="!!formData.attachments.length"
            color="transparent"
            small
            :size="25"
            @pick="({ attachment }) => formData.attachments.push(attachment)"
            @close="$refs.textarea.focus()"
          />

          <!-- Poll -->
          <v-btn
            text
            small
            :disabled="!canCreatePoll"
            @click="pollSelected = true"
          >
            <v-icon left size="25">mdi-chart-box</v-icon>
            {{ $t("polls.poll") }}
          </v-btn>
        </template>

        <v-spacer />

        <v-btn
          type="submit"
          color="primary"
          :disabled="!canSubmit"
          :loading="loading"
          rounded
          width="100"
        >
          {{ editablePost ? $t("common.save") : $t("posts.post") }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { find as linkifyFind } from "linkifyjs";
import tribute from "@/plugins/tribute";
import EmojiMenu from "@/components/common/EmojiMenu.vue";
import GiphyPicker from "@/components/common/GiphyPicker.vue";
import PostAttachments from "./PostAttachments.vue";
import PollForm from "@/components/app/polls/PollForm.vue";

export default {
  components: { EmojiMenu, GiphyPicker, PostAttachments, PollForm },

  props: {
    editablePost: Object,
    shareablePost: Object,
    group: Object,
    disableAttachments: Boolean,
  },

  data: () => ({
    formData: {
      content: "",
      attachments: [],
      poll: {},
    },
    formResponse: null,
    loading: false,
    pollSelected: false,
    pollValid: false,
  }),

  computed: {
    canSubmit() {
      return (
        this.formData.content ||
        this.formData.attachments.length ||
        this.shareablePost ||
        (this.pollSelected && this.pollValid)
      );
    },

    canCreatePoll() {
      return !this.pollSelected && !this.shareablePost && !this.editablePost;
    },
  },

  created() {
    this.initForm();
  },

  mounted() {
    tribute.attach(this.$refs.textarea.$refs.input);
  },

  methods: {
    initForm() {
      if (!this.editablePost) {
        return;
      }

      const post = Object.assign({}, this.editablePost);
      this.formData.content = post.content;
      this.formData.attachments = JSON.parse(JSON.stringify(post.attachments));
    },

    onSubmit() {
      if (this.editablePost) {
        this.updatePost();
      } else if (this.shareablePost) {
        this.sharePost();
      } else {
        this.createPost();
      }
    },

    createPost() {
      this.loading = true;
      this.formResponse = null;
      let action = "posts/create";
      let payload = this.getPayload();

      if (this.group) {
        action = "posts/createGroupPost";
      }

      this.$store
        .dispatch(action, payload)
        .then((post) => {
          this.resetForm();
          this.$emit("created", post);
        })
        .catch((data) => {
          this.formResponse = data;
          this.$toast.error("Error while creating post.");
        })
        .finally(() => {
          this.loading = false;
        });
    },

    updatePost() {
      this.loading = true;
      this.formResponse = null;
      this.$store
        .dispatch("posts/update", this.getPayload())
        .then((post) => {
          for (const key in this.editablePost) {
            if (
              Object.hasOwnProperty.call(this.editablePost, key) &&
              post[key] !== undefined
            ) {
              this.$set(this.editablePost, key, post[key]);
            }
          }

          this.$emit("updated", this.editablePost);
        })
        .catch((data) => {
          this.formResponse = data;
          this.$toast.error("Error while updating post.");
        })
        .finally(() => {
          this.loading = false;
        });
    },

    sharePost() {
      this.loading = true;
      this.formResponse = null;
      this.$store
        .dispatch("posts/share", this.getPayload())
        .then((post) => {
          this.resetForm();
          this.$emit("shared", post);
        })
        .catch((data) => {
          this.formResponse = data;
          this.$toast.error("Error while sharing post.");
        })
        .finally(() => {
          this.loading = false;
        });
    },

    addAttachments(items) {
      if (!items) {
        return;
      }

      if (!Array.isArray(items)) {
        items = [items];
      }

      for (let i = 0; i < items.length; i++) {
        const file = items[i];

        if (!file) {
          continue;
        }

        file.$type = file.type.split("/")[0];

        if (this.formData.attachments.length >= 4) {
          this.$toast.warning("File limit reached.");
          break;
        }

        if (file instanceof File && !this.isValidFileSize(file)) {
          continue;
        }

        this.formData.attachments.push(file);
      }

      this.validateAttachments();
    },

    validateAttachments() {
      let imagesCount = 0;
      let gifCount = 0;
      let videosCount = 0;
      let otherCount = 0;

      this.formData.attachments.forEach((file) => {
        const type = file.type.split("/")[0];

        switch (type) {
          case "image":
            ++imagesCount;
            break;

          case "gif":
            ++gifCount;
            break;

          case "video":
          case "audio":
            ++videosCount;
            break;

          default:
            ++otherCount;
            break;
        }
      });

      if (
        imagesCount > 4 ||
        gifCount > 1 ||
        videosCount > 1 ||
        otherCount ||
        (imagesCount && videosCount) ||
        (imagesCount && gifCount) ||
        (gifCount && videosCount)
      ) {
        this.formData.attachments = [];
        this.$toast.warning(this.$t("posts.attachmentsInvalid"), {
          duration: 5000,
        });
      }
    },

    isValidFileSize(file) {
      const type = file.type.split("/")[0];
      const size = file.size / 1000;
      const maxSize =
        type === "image" || type === "gif"
          ? this.$config.attachments.maxImageSize
          : type === "video"
          ? this.$config.attachments.maxVideoSize
          : this.$config.attachments.maxDocumentSize;

      if (size > maxSize) {
        this.$toast.warning(
          this.$t("common.maxUploadSize", { n: maxSize + "KB" })
        );
        return false;
      }

      return true;
    },

    getPayload() {
      let payload = Object.assign({}, this.formData);

      if (!payload.content) {
        delete payload.content;
      }

      if (!payload.attachments.length) {
        delete payload.attachments;
      } else if (
        payload.attachments[0].type === "gif" &&
        !payload.attachments[0].id
      ) {
        payload.gif = payload.attachments[0];
        delete payload.attachments;
      } else {
        payload.attachments = payload.attachments.filter((i) => !i.id);
      }

      payload.mentions = linkifyFind(payload.content || "", "mention").map(
        (i) => i.value.substring(1)
      );

      if (this.group) {
        payload = {
          id: this.group.id,
          payload,
        };
      } else if (this.editablePost) {
        payload = {
          id: this.editablePost.id,
          payload,
        };
      } else if (this.shareablePost) {
        payload.original_post_id = this.shareablePost.id;
      }

      return payload;
    },

    onPollRemove() {
      this.pollSelected = false;
      this.formData.poll = {};
    },

    resetForm() {
      this.$refs.form.reset();
      this.formData = {
        content: "",
        attachments: [],
        poll: {},
      };
      this.pollSelected = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
