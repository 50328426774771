<template>
  <v-dialog v-model="dialog" width="600" eager>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="{ ...attrs, ...$attrs }" v-on="on">
        <v-icon :left="!$attrs.icon" :size="$attrs.size || undefined">
          mdi-file-gif-box
        </v-icon>
        <template v-if="!$attrs.icon">{{ $t("common.gif") }}</template>
      </v-btn>
    </template>

    <v-card>
      <!-- Header -->
      <v-card-title class="pa-3" style="flex-wrap: nowrap">
        <!-- Giphy credit image -->
        <img
          :src="require('@/assets/images/giphy-vert.png')"
          alt="Giphy credit image"
        />

        <!-- Search -->
        <SearchInput
          v-model="searchInput"
          :placeholder="$t('common.search')"
          ref="searchInput"
          class="rounded-lg mx-2"
          style="max-width: 300px"
          background-color="ocean-dark"
          @search="render"
        />

        <v-spacer />

        <!-- Close button -->
        <close-button @click="dialog = false" />
      </v-card-title>

      <!-- Main -->
      <v-card-text class="pa-3 pt-0" style="max-height: 600px">
        <!-- Spinner -->
        <spinner v-show="showSpinner" large center style="height: 300px" />

        <!-- Giphy grid -->
        <div v-show="!showSpinner" ref="giphy"></div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { renderGrid } from "@giphy/js-components";
import { GiphyFetch } from "@giphy/js-fetch-api";
import SearchInput from "@/components/common/SearchInput.vue";

export default {
  name: "GiphyPicker",

  components: { SearchInput },

  props: {
    value: {
      type: [Object, File],
    },
  },

  data: () => ({
    dialog: false,
    giphyFetch: null,
    giphyGrid: null,
    searchInput: "",
    showSpinner: false,
  }),

  watch: {
    dialog(val) {
      if (val) {
        this.render();
        this.$nextTick(() => {
          this.$refs.searchInput.$el.querySelector("input").focus();
        });
      } else {
        this.onClosed();
      }
    },
  },

  beforeDestroy() {
    this.removeGrid();
  },

  methods: {
    render() {
      this.removeGrid();
      const width = this.getWidth();
      this.giphyFetch =
        this.giphyFetch || new GiphyFetch(this.$utils.env("GIPHY_TOKEN"));

      this.giphyGrid = renderGrid(
        {
          width,
          columns: width < 500 ? 2 : 3,
          gutter: 6,
          noLink: true,
          noResultsMessage: this.$t("common.noResultsLong"),
          fetchGifs: this.fetchGifs,
          onGifClick: this.onGifClick,
        },
        this.$refs.giphy
      );
    },

    getWidth() {
      if (window.innerWidth < 580) {
        return window.innerWidth;
      }

      return 580;
    },

    fetchGifs(offset) {
      this.showSpinner = offset === 0;

      return this.searchInput
        ? this.giphyFetch
            .search(this.searchInput, { offset, limit: 15 })
            .finally(() => {
              this.showSpinner = false;
            })
        : this.giphyFetch.trending({ offset, limit: 15 }).finally(() => {
            this.showSpinner = false;
          });
    },

    removeGrid() {
      this.giphyGrid && this.giphyGrid();
      this.giphyGrid = null;
    },

    onGifClick(gif) {
      const attachment = {
        attachment_name: gif.title,
        attachment_url: gif.images.fixed_height.url,
        type: "gif",
        mime: "gif",
        file_size: gif.images?.fixed_height?.size || 0,
      };

      this.$emit("input", attachment);
      this.$emit("pick", {
        gif,
        attachment,
      });
      this.dialog = false;
    },

    onClosed() {
      this.removeGrid();
      this.searchInput = "";
      this.$emit("close");
    },
  },
};
</script>

<style></style>
