<template>
  <div class="group-row d-flex align-center">
    <!-- Avatar -->
    <router-link :to="{ name: 'app.group', params: { id: group.id } }">
      <v-avatar class="avatar mr-3" size="50">
        <v-img
          :src="$utils.userImage(group, '50x50')"
          width="50"
          height="50"
          :alt="group.name"
        />
      </v-avatar>
    </router-link>

    <!-- Name -->
    <div class="flex-grow-1">
      <router-link
        class="name btn-basic"
        :to="{ name: 'app.group', params: { id: group.id } }"
      >
        {{ group.name }}
      </router-link>

      <small v-if="description" class="description text--secondary">
        {{ group.short_description }}
      </small>
    </div>

    <!-- Actions -->
    <div v-if="!hideActions" class="actions ml-1">
      <GroupJoinButton :group="group" icon color="white" />
    </div>

    <!-- Slot:append -->
    <slot name="append" />
  </div>
</template>

<script>
import GroupJoinButton from "./GroupJoinButton.vue";

export default {
  components: { GroupJoinButton },

  props: {
    group: Object,
    hideActions: Boolean,
    description: Boolean,
  },
};
</script>

<style lang="scss">
.description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
