<template>
  <v-dialog v-model="dialog" :width="$attrs.width || 500">
    <!-- Activator -->
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }">
        <v-btn class="mt-3" small v-bind="attrs" v-on="on">
          {{ $t("common.users") }}
        </v-btn>
      </slot>
    </template>

    <v-card class="user-list-tab-modal">
      <!-- Title -->
      <v-card-title>
        <v-btn
          v-for="(tab, i) in tabs"
          v-bind="tab.btnAttrs || {}"
          :key="i"
          small
          rounded
          :color="i === activeTab ? 'primary' : 'secondary'"
          @click="setTab(i)"
        >
          {{ tab.title }}
        </v-btn>

        <v-spacer />

        <close-button @click="dialog = false" />
      </v-card-title>

      <v-divider />

      <!-- Main -->
      <v-card-text>
        <!-- Search -->
        <SearchInput
          v-if="searchable"
          v-model="searchInput"
          class="rounded-lg mb-5"
          @search="search"
        />

        <!-- Loader -->
        <template v-if="!meta.current_page && $loading(tab.action)">
          <spinner medium center />
        </template>

        <!-- Users -->
        <template v-else-if="users.length">
          <UserRow v-for="user in users" :key="user.id" :user="user">
            <template v-slot:actions>
              <slot name="actions" v-bind="{ user, activeTab }" />
            </template>
          </UserRow>

          <div v-if="$utils.canLoadMore(meta)" class="load-more text-center">
            <v-btn
              color="primary"
              :loading="meta && $loading(tab.action)"
              small
              rounded
              @click="fetchData"
            >
              {{ $t("common.loadMore") }}
            </v-btn>
          </div>
        </template>

        <!-- Empty -->
        <template v-else>
          <v-alert text class="mb-0">
            {{ $t("common.noDataFound") }}
          </v-alert>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import SearchInput from "@/components/common/SearchInput.vue";
import UserRow from "./UserRow.vue";

export default {
  components: { SearchInput, UserRow },

  props: {
    tabs: Array,
    defaultTab: Number,
    searchable: Boolean,
  },

  data: () => ({
    dialog: false,
    activeTab: 0,
    searchInput: "",
    users: [],
    meta: {
      current_page: 0,
    },
  }),

  computed: {
    tab() {
      return this.tabs[this.activeTab];
    },
  },

  watch: {
    dialog(val) {
      if (val && this.defaultTab !== undefined) {
        this.activeTab = this.defaultTab;
      }

      val ? this.fetchData() : this.reset(true);
    },
  },

  methods: {
    fetchData(search = null) {
      const params = this.tab.params.params || this.tab.params;
      params.page = this.meta.current_page + 1;

      if (search) {
        params["search[username]"] = search;
      } else {
        delete params["search[username]"];
      }

      this.$store
        .dispatch(this.tab.action, this.tab.params)
        .then((res) => {
          this.meta = res.meta;
          this.users.push(...res.data);
          this.$emit("data:fetched", res);
        })
        .catch(() => {
          this.$toast.error("Error while fetching users.");
        });
    },

    search(q) {
      this.reset();
      this.fetchData(q);
    },

    setTab(i) {
      this.activeTab = i;
      this.reset(true);
      this.fetchData();
    },

    reset(full = false) {
      this.users = [];
      this.meta = {
        current_page: 0,
      };

      if (full) {
        this.searchInput = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-row:not(:last-of-type) {
  margin-bottom: 20px;
}

.v-card__title {
  .v-btn:not(:last-of-type) {
    margin-right: 10px;
  }
}
</style>
