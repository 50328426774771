<template>
  <div class="trending-hashtags-widget widget">
    <label class="label">
      {{ $t("hashtags.trending") }}
    </label>

    <div class="card pa">
      <!-- Loader -->
      <spinner v-if="$loading('hashtags/fetchTrending')" center />

      <!-- List -->
      <template v-else>
        <div
          class="item d-flex align-center"
          v-for="(item, i) in items"
          :key="i"
        >
          <!-- Fire -->
          <v-icon v-if="item.is_hot" color="primary" size="28" class="mr-2">
            mdi-fire
          </v-icon>

          <!-- Hashtag -->
          <div>
            <router-link
              class="hashtag d-block btn-basic"
              :to="{
                name: 'app.search',
                params: { index: 'posts' },
                query: { q: `#${item.name}` },
              }"
            >
              #{{ item.name }}
            </router-link>

            <small class="text--secondary font-weight-medium">
              {{ $t("hashtags.nMentions", { n: item.mentions_count }) }}
            </small>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    items() {
      return this.$store.getters["hashtags/trending"];
    },
  },

  created() {
    this.$store.dispatch("hashtags/fetchTrending");
  },
};
</script>

<style lang="scss" scoped>
.item {
  &:not(:last-of-type) {
    margin-bottom: 15px;
  }

  .hashtag {
    margin-bottom: -5px;
  }
}
</style>
