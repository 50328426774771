<template>
  <v-dialog v-model="dialog" width="500">
    <!-- Activator -->
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }">
        <v-btn class="mt-3" small v-bind="attrs" v-on="on">
          {{ $t("common.users") }}
        </v-btn>
      </slot>
    </template>

    <v-card class="user-list-modal">
      <!-- Title -->
      <v-card-title class="d-flex">
        <h3 v-if="title">
          {{ title }}
        </h3>
        <v-spacer />
        <close-button @click="dialog = false" />
      </v-card-title>

      <v-divider />

      <!-- Main -->
      <v-card-text>
        <!-- Loader -->
        <template v-if="!meta.current_page && $loading(action)">
          <spinner medium center />
        </template>

        <!-- Users -->
        <template v-else-if="users.length">
          <UserRow v-for="user in users" :key="user.id" :user="user">
            <template v-slot:actions>
              <slot name="actions" v-bind="{ user }" />
            </template>
          </UserRow>

          <div v-if="$utils.canLoadMore(meta)" class="load-more text-center">
            <v-btn
              color="primary"
              :loading="meta && $loading(action)"
              small
              rounded
              @click="fetchData"
            >
              {{ $t("common.loadMore") }}
            </v-btn>
          </div>
        </template>

        <!-- Empty -->
        <template v-else>
          <v-alert text class="mb-0">
            {{ $t("common.noDataFound") }}
          </v-alert>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import UserRow from "./UserRow.vue";

export default {
  components: { UserRow },
  props: {
    title: String,
    action: String,
    params: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    dialog: false,
    users: [],
    meta: {
      current_page: 0,
    },
  }),

  watch: {
    dialog(val) {
      val ? this.fetchData() : this.reset();
    },
  },

  methods: {
    fetchData() {
      const params = this.params.params || this.params;
      params.page = this.meta.current_page + 1;

      this.$store
        .dispatch(this.action, this.params)
        .then((res) => {
          this.meta = res.meta;
          this.users.push(...res.data);
        })
        .catch(() => {
          this.$toast.error("Error while fetching users.");
        });
    },

    reset() {
      this.users = [];
      this.meta = {
        current_page: 0,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.user-row:not(:last-of-type) {
  margin-bottom: 20px;
}
</style>
