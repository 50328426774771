<template>
  <v-form v-model="formValid" ref="form" class="poll-form" :class="{ xs: $xs }">
    <div class="form-input">
      <label>{{ $t("polls.title") }}</label>

      <!-- Title -->
      <v-text-field
        v-model="internalValue.title"
        :label="$t('polls.title')"
        :rules="[$rules.required, $rules.string.max(140)]"
        outlined
        counter
        maxlength="140"
        autofocus
        dense
      />
    </div>

    <div class="form-input">
      <label>{{ $t("polls.duration") }}</label>

      <PollDurationPicker :poll="internalValue" />
    </div>

    <div
      v-for="(option, i) in internalValue.poll_options"
      :key="i"
      class="form-input"
    >
      <label>{{ $t("polls.option") + ` #${i + 1}` }}</label>

      <!-- Options -->
      <v-text-field
        v-model="option.text"
        :label="$t('polls.option') + ` #${i + 1}`"
        :rules="[$rules.required, $rules.string.max(40)]"
        :append-outer-icon="i > 1 ? 'mdi-close' : undefined"
        outlined
        counter
        dense
        maxlength="40"
        @click:append-outer="removeOption(i)"
      />
    </div>

    <!-- Actions -->
    <div class="d-flex align-center gap-10">
      <!-- Add option -->
      <v-btn
        v-if="showAddOption"
        color="secondary"
        small
        rounded
        @click="addOption"
      >
        <v-icon>mdi-plus</v-icon>
        {{ $t("polls.addOption") }}
      </v-btn>

      <v-spacer />

      <v-select
        v-model="internalValue.visibility_type"
        :label="$t('polls.resultsVisibility')"
        :items="visibilityTypes"
        background-color="secondary"
        filled
        rounded
        hide-details
        dense
        style="max-width: 250px"
        menu-props="offsetY"
      />

      <!-- Remove poll -->
      <v-btn text small color="error" rounded @click="$emit('remove')">
        {{ $t("polls.remove") }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import PollDurationPicker from "./PollDurationPicker.vue";

export default {
  components: { PollDurationPicker },

  props: {
    value: Object,
    isValid: Boolean,
  },

  data() {
    return {
      visibilityTypes: [
        { text: this.$t("polls.resultsPublic"), value: "public" },
        { text: this.$t("polls.resultsHidden"), value: "hidden" },
        {
          text: this.$t("polls.resultsHiddenWhileActive"),
          value: "hidden-while-active",
        },
      ],
    };
  },

  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    formValid: {
      get() {
        return this.isValid;
      },
      set(val) {
        this.$emit("update:isValid", val);
      },
    },
    showAddOption() {
      return this.internalValue?.poll_options?.length < 4;
    },
  },

  created() {
    this.init();
  },

  mounted() {
    this.$refs.form.resetValidation();
  },

  methods: {
    addOption() {
      this.internalValue.poll_options.push({ text: "" });
    },

    removeOption(index) {
      this.internalValue.poll_options.splice(index, 1);
    },

    init() {
      this.internalValue = {
        title: null,
        ends_at: null,
        poll_options: [{ text: null }, { text: null }],
        visibility_type: "public",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.poll-form {
  .form-input {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    label {
      display: block;
      min-width: 150px;
      padding-bottom: 24px;
      font-size: 14px;
    }
  }

  &.xs {
    .form-input label {
      display: none;
    }
  }
}
</style>
