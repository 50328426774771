<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" icon v-bind="attrs" v-on="on">
        <v-icon> mdi-share-variant-outline </v-icon>
      </v-btn>
    </template>

    <v-card class="post-share-modal">
      <!-- Title -->
      <v-card-title>
        <h3>
          {{ $t("posts.sharePost") }}
        </h3>
        <v-spacer />
        <close-button @click="dialog = false" />
      </v-card-title>

      <v-divider />

      <!-- Main -->
      <v-card-text v-if="dialog">
        <PostForm :shareablePost="post" disableAttachments @shared="onShared" />

        <Post
          :post="post"
          hideActions
          hideComments
          class="border rounded-lg pa-3 mt-5"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import PostForm from "./PostForm.vue";

export default {
  components: { Post: () => import("./Post.vue"), PostForm },

  props: {
    post: Object,
  },

  data: () => ({
    dialog: false,
  }),

  methods: {
    close() {
      this.dialog = false;
    },

    onShared() {
      this.$emit("shared");
      this.close();
    },
  },
};
</script>

<style></style>
