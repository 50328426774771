<template>
  <div class="calendar-event rounded" :class="event.color || 'primary'">
    <div class="event-title">{{ event.title }}</div>

    <div v-if="event.subtitle" class="event-subtitle">
      {{ event.subtitle }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    event: Object,
  },
};
</script>

<style lang="scss" scoped>
.calendar-event {
  padding: 5px;
}

.event-title {
  font-weight: 500;
}

.event-subtitle {
  opacity: 0.5;
  font-size: 14px;
}

.event-title,
.event-subtitle {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
