<template>
  <div class="post-feed">
    <label v-if="label !== null">{{ label || $t("common.feed") }}</label>

    <!-- Loader -->
    <template v-if="!meta.current_page && $loading(action)">
      <spinner medium center style="height: 200px" />
    </template>

    <!-- Posts -->
    <template v-else-if="posts.length">
      <Post
        v-for="post in posts"
        :key="post.id"
        :post="post"
        class="widget card pa"
      />

      <div v-if="$utils.canLoadMore(meta)" class="load-more text-center">
        <v-btn
          color="primary"
          :loading="meta && $loading(action)"
          small
          rounded
          @click="fetchData"
        >
          {{ $t("common.loadMore") }}
        </v-btn>
      </div>
    </template>

    <!-- Empty -->
    <template v-else>
      <slot name="empty">
        <div class="widget card pa">
          {{ emptyText || $t("feed.empty") }}
        </div>
      </slot>
    </template>
  </div>
</template>

<script>
import Post from "./Post.vue";

export default {
  components: { Post },

  props: {
    label: String,
    action: String,
    params: {
      type: Object,
      default: () => ({}),
    },
    emptyText: String,
  },

  data: () => ({
    posts: [],
    meta: {
      current_page: 0,
    },
  }),

  created() {
    this.fetchData();
    this.$eventBus.$on("post:created", (post) => {
      console.log("post:created");
      this.posts.unshift(post);
    });
  },

  destroyed() {
    this.$eventBus.$off("post:created");
  },

  methods: {
    fetchData() {
      const params = {
        ...this.params,
        page: this.meta.current_page + 1,
      };

      this.$store
        .dispatch(this.action, params)
        .then((res) => {
          this.meta = res.meta || { current_page: 0 };
          this.posts.push(...res.data);
        })
        .catch(() => {
          this.$toast.error("Error while fetching posts.");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.post-feed {
  label {
    display: block;
    margin-bottom: 10px;
    color: var(--v-primary-base);
    font-size: 18px;
    font-weight: 600;
  }

  > .post {
    &:not(:last-of-type) {
      margin-bottom: 24px;
    }
  }
}
</style>
