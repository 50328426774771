<template>
  <div
    v-if="items.length"
    class="post-attachments"
    :class="{ 'single-item': itemCount === 1 }"
  >
    <!-- Video -->
    <Plyr
      v-if="isVideo"
      :src="items[0].attachment_url"
      class="pos-rel"
      style="width: 100%; max-width: 500px; max-height: 500px"
    >
      <template v-slot:append>
        <v-btn
          v-if="editable"
          color="primary"
          fab
          small
          style="position: absolute; top: 7px; right: 7px"
          @click.stop.prevent="deleteItem(0)"
        >
          <v-icon>mdi-cancel</v-icon>
        </v-btn>
      </template>
    </Plyr>

    <!-- Images -->
    <template v-else>
      <a
        v-for="(item, index) in items"
        :key="index"
        :href="item.attachment_url"
        class="glightbox pos-rel mr-2 d-inline-flex"
        :data-gallery="key"
      >
        <!-- Images -->
        <img
          v-if="item.type === 'image' || item.type === 'gif'"
          :src="item.attachment_url"
          :style="itemStyle"
          alt="Post image"
          class="rounded-lg cursor-pointer"
          @error="onError(item)"
        />

        <!-- Delete button -->
        <v-btn
          v-if="editable && isDeletable(index)"
          color="primary"
          fab
          small
          style="position: absolute; top: 7px; right: 7px"
          @click.stop.prevent="deleteItem(index)"
        >
          <v-icon>mdi-cancel</v-icon>
        </v-btn>
      </a>
    </template>
  </div>
</template>

<script>
import Plyr from "@/components/common/Plyr.vue";
import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.min.css";

export default {
  components: { Plyr },

  props: {
    value: Array,
    post: Object,
    isComment: Boolean,
    editable: Boolean,
    maxHeight: String,
  },

  data: () => ({
    glightbox: null,
    key: "",
  }),

  computed: {
    attachments: {
      get() {
        return this.value || this.post.attachments;
      },
      set(val) {
        this.$emit("input", val);
      },
    },

    itemStyle() {
      if (this.itemCount === 1) {
        return `max-width: 500px; max-height: ${
          this.maxHeight || "500px"
        }; object-fit: cover;`;
      } else if (this.itemCount === 2) {
        return "width: 400px; height: 400px; object-fit: cover;";
      } else if (this.itemCount === 3) {
        return "width: 266px; height: 266px; object-fit: cover;";
      } else {
        return "width: 200px; height: 200px; object-fit: cover;";
      }
    },

    items() {
      return this.attachments && this.attachments.length
        ? this.attachments.map((i) => this.mapItem(i))
        : [];
    },

    isVideo() {
      return ["video", "audio"].includes(this.items[0].type);
    },

    itemCount() {
      return this.items.length;
    },
  },

  created() {
    this.key = new Date().getTime();
  },

  mounted() {
    this.initGlightbox();
  },

  methods: {
    initGlightbox() {
      this.glightbox = GLightbox();
    },

    mapItem(i) {
      if (i instanceof File) {
        i = {
          type: i.type.split("/")[0],
          attachment_url: URL.createObjectURL(i),
        };
      }

      return i;
    },

    isDeletable(index) {
      return !this.attachments[index].id || !!this.post.content;
    },

    async deleteItem(index) {
      const attachment = this.attachments[index];

      // Check if item is local file file
      if (!attachment.id) {
        this.attachments.splice(index, 1);
        return;
      }

      const action = this.isComment
        ? "comments/deleteAttachment"
        : "posts/deleteAttachment";

      this.$store
        .dispatch(action, {
          id: this.post.id,
          attachmentId: attachment.id,
        })
        .then(() => {
          this.attachments.splice(index, 1);
          this.post.attachments.splice(index, 1);
        })
        .catch(() => {
          this.$toast.error("Error while deleting attachment.");
        });
    },

    onError(img) {
      const altSrc = `https://placehold.jp/400x200.png?text=Error`;
      this.$set(img, "attachment_url", altSrc);
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.single-item {
  @media screen and (max-width: 530px) {
    img {
      max-width: 100% !important;
    }
  }
}
</style>
