<template>
  <div class="calendar-widget widget">
    <!-- Label -->
    <label class="label">
      {{ $t("calendar.calendar") }}
    </label>

    <!-- Card -->
    <div class="card pa pb-0">
      <!-- Day picker -->
      <v-slide-group
        v-model="selectedDate"
        class="day-picker mb-5"
        center-active
      >
        <v-slide-item
          v-for="(date, i) in dayPickerDates"
          :key="i"
          v-slot="{ active }"
          :value="date"
        >
          <div
            class="day-picker-date text-center cursor-pointer px-4"
            :class="{ 'text--secondary': !active }"
            @click="selectedDate = date"
          >
            <div class="font-weight-bold">{{ date.format("dddd") }}</div>
            <small class="d-block">{{ $utils.formatDate(date) }}</small>
          </div>
        </v-slide-item>
      </v-slide-group>

      <!-- Spinner -->
      <spinner v-if="loading" center class="mb-10 mt-10" />

      <!-- Empty -->
      <v-alert v-else-if="!events.length" color="info" text>
        {{ $t("calendar.noEventsForDate") }}
      </v-alert>

      <!-- Calendar -->
      <div v-else class="calendar" ref="calendar" style="max-height: 250px">
        <div
          class="calendar-item d-flex"
          v-for="event in events"
          :key="event.id"
          :ref="`event${event.id}`"
        >
          <!-- Time -->
          <div class="time pt-2">
            <div>{{ $utils.formatTime(event.start_date) }}</div>
            <div class="text--secondary">
              {{ $utils.formatTime(event.end_date) }}
            </div>
          </div>

          <!-- Event -->
          <div class="content flex-grow-1 pl-5 py-2">
            <CalendarEvent :event="event" class="pa-3" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "@/plugins/dayjs";
import CalendarEvent from "./CalendarEvent.vue";

export default {
  components: { CalendarEvent },

  data: () => ({
    loading: false,
    selectedDate: null,
    events: [
      {
        id: 1244,
        title: "Barcelona game",
        subtitle: "League C3-PO",
        start_date: "2022-09-06 11:35",
        end_date: "2022-09-06 13:05",
        color: "primary",
      },
      {
        id: 3549,
        title: "Team practice",
        subtitle: "Team 11 - Stadium turtle",
        start_date: "2022-09-06 13:30",
        end_date: "2022-09-06 15:00",
        color: "info",
      },
      {
        id: 3698,
        title: "Real Madrid vs. Arsenal",
        subtitle: "League C3-PO",
        start_date: "2022-09-06 18:00",
        end_date: "2022-09-06 20:00",
        color: "warning",
      },
      {
        id: 3998,
        title: "Fake Event",
        start_date: "2022-09-06 22:00",
        end_date: "2022-09-06 22:30",
      },
    ],
  }),

  computed: {
    dayPickerDates() {
      return [dayjs().subtract(1, "day"), dayjs(), dayjs().add(1, "day")];
    },
  },

  watch: {
    selectedDate: "fetchData",
  },

  created() {
    this.selectedDate = this.dayPickerDates[1];
  },

  methods: {
    fetchData() {
      this.loading = true;

      setTimeout(() => {
        this.loading = false;

        this.$nextTick(() => {
          this.scrollToCurrentEvent();
        });
      }, 1000);
    },

    scrollToCurrentEvent() {
      if (!this.events.length) {
        return;
      }

      let currentEvent = null;

      for (let i = 0; i < this.events.length; ++i) {
        if (dayjs().isBefore(this.events[i].end_date)) {
          currentEvent = this.events[i];
          break;
        }
      }

      if (currentEvent) {
        this.$refs.calendar.scrollTop =
          this.$refs[`event${currentEvent.id}`][0]?.offsetTop;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar {
  -webkit-mask-image: linear-gradient(to bottom, $ocean 80%, transparent 100%);
  mask-image: linear-gradient(to bottom, $ocean 80%, transparent 100%);
}

.day-picker {
  letter-spacing: 0px;
  mask-image: linear-gradient(
    transparent,
    $ocean 20%,
    $ocean 80%,
    transparent 100%
  );
  -webkit-mask-image: linear-gradient(
    to right,
    transparent,
    $ocean 20%,
    $ocean 80%,
    transparent 100%
  );

  :deep {
    .v-slide-group__prev,
    .v-slide-group__next {
      display: none;
    }
  }
}

.calendar {
  margin-right: -20px;
  padding-right: 20px;
  padding-bottom: 20px;
  overflow: hidden;

  &:hover {
    overflow: auto;
  }
}

.time {
  min-width: 65px;
  font-weight: 500;
  border-right: 3px solid $gray;
}
</style>
