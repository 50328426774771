<template>
  <div
    v-if="!post.is_deleted"
    :id="`post-${post.id}`"
    class="post"
    :class="{ fresh: post.fresh }"
  >
    <!-- Header -->
    <PostHeader :post="post" :small="small" :hideMenu="hideMenu" />

    <!-- Content -->
    <p
      ref="content"
      v-html="linkifiedContent"
      class="content linkified mt-3 mb-0 text-formatted"
      :class="{ 'font-size-14': small }"
    />

    <!-- Attachments -->
    <PostAttachments :post="post" class="mt-3" />

    <!-- Poll -->
    <Poll v-if="post.poll" :poll="post.poll" class="mt-2" />

    <!-- Shared post -->
    <Post
      v-if="post.original_post"
      :post="post.original_post"
      small
      hideActions
      hideComments
      hideMenu
      class="border rounded-lg pa-3 mt-2"
    />

    <!-- Actions -->
    <PostActions v-if="!hideActions" :post="post" class="mt-1" />

    <template v-if="!hideComments">
      <!-- Divider -->
      <v-divider class="mb-4 mt-1" />

      <!-- Comments -->
      <CommentFeed :post="post" class="mt-3" />
    </template>
  </div>
</template>

<script>
import linkify, { setRouting } from "@/plugins/linkify";
import Poll from "@/components/app/polls/Poll.vue";
import PostActions from "./PostActions.vue";
import PostAttachments from "./PostAttachments.vue";
import PostHeader from "./PostHeader.vue";
import CommentFeed from "./comments/CommentFeed.vue";

export default {
  components: {
    Post: () => import("./Post"),
    Poll,
    PostActions,
    PostAttachments,
    PostHeader,
    CommentFeed,
  },

  props: {
    post: Object,
    small: Boolean,
    hideActions: Boolean,
    hideComments: Boolean,
    hideMenu: Boolean,
  },

  computed: {
    linkifiedContent() {
      return linkify(this.post.content);
    },
  },

  created() {
    if (this.post.fresh) {
      setTimeout(() => {
        this.$set(this.post, "fresh", false);
      }, 7000);
    }
  },

  mounted() {
    setRouting(this.$refs.content);
  },
};
</script>

<style lang="scss" scoped>
.fresh {
  border: 1px solid var(--v-primary-base);
}
</style>
