import Tribute from "tributejs";
import store from "@/store";
import i18n from "@/plugins/i18n";
import debounce from "@/utils/debounce";
import { userImage } from "@/utils/utils";

export default new Tribute({
  values: debounce(async function (text, cb) {
    let users = [];

    if (text.length > 1) {
      await store
        .dispatch("users/search", {
          q: text,
        })
        .then((data) => {
          users = data.data;
        });
    }

    cb(users);
  }, 350),

  lookup: function (item) {
    return item.username;
  },

  fillAttr: "username",

  searchOpts: {
    skip: false,
  },

  selectTemplate: function (item) {
    return "@" + item.original.username;
  },

  menuItemTemplate: function (item) {
    const avatar = userImage(item.original);
    const username = item.original.username;
    const alt = `Avatar of ${username}`;

    return `
      <div style="display: flex; align-items: center">
        <img src="${avatar}" alt="${alt}" style="width: 40px; height: 40px; border-radius: 50%" />

        <div style="margin-left: 10px;">
          ${username}
        </div>
      </div>
    `;
  },

  noMatchTemplate: function () {
    const text = i18n.t("common.noResults");

    return `<li>${text}</li>`;
  },
});
