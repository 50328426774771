<template>
  <column-layout class="page" sticky-sides>
    <!-- Left -->
    <template v-slot:left>
      <Menu />

      <TrendingHashtagsWidget class="my-7" />

      <MetaverseBanner />
    </template>

    <!-- Main -->
    <template>
      <PostForm class="widget card pa label-empty mb-7" />

      <PostFeed action="posts/fetchFeed" />
    </template>

    <!-- Right -->
    <template v-slot:right>
      <CalendarWidget v-if="!$utils.$env.prod" class="mb-7" />

      <SuggestionsWidget />
    </template>
  </column-layout>
</template>

<script>
import CalendarWidget from "@/components/app/calendar/CalendarWidget.vue";
import Menu from "@/components/app/Menu.vue";
import MetaverseBanner from "@/components/app/feed/MetaverseBanner.vue";
import PostForm from "@/components/app/posts/PostForm.vue";
import PostFeed from "@/components/app/posts/PostFeed.vue";
import SuggestionsWidget from "@/components/app/feed/SuggestionsWidget.vue";
import TrendingHashtagsWidget from "@/components/app/feed/TrendingHashtagsWidget.vue";

export default {
  metaInfo: { title: "Feed" },

  components: {
    CalendarWidget,
    Menu,
    MetaverseBanner,
    PostForm,
    PostFeed,
    SuggestionsWidget,
    TrendingHashtagsWidget,
  },

  created() {
    this.$eventBus.$on("feed:refresh", this.refreshFeed);
  },

  beforeDestroy() {
    this.$eventBus.$off("feed:refresh");
  },

  methods: {
    refreshFeed() {
      //
    },
  },
};
</script>

<style lang="scss"></style>
