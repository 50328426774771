export function pushUnique(arr, property, ...items) {
  items.forEach((item) => {
    const exists = arr.findIndex((i) => i[property] === item[property]) !== -1;

    if (!exists) {
      arr.push(item);
    }
  });
}

export function unshiftUnique(arr, property, ...items) {
  items.forEach((item) => {
    const exists = arr.findIndex((i) => i[property] === item[property]) !== -1;

    if (!exists) {
      arr.unshift(item);
    }
  });
}
