<template>
  <div class="post-header d-flex">
    <!-- Avatar -->
    <UserHoverCard :data="post.user">
      <router-link
        :to="{ name: 'app.user', params: { id: post.user.username } }"
      >
        <v-avatar class="avatar mr-3" :size="avatarSize">
          <v-img
            :src="$utils.userImage(post.user, `${avatarSize}x${avatarSize}`)"
            :width="avatarSize"
            :height="avatarSize"
            :alt="post.user.username"
          />
        </v-avatar>
      </router-link>
    </UserHoverCard>

    <div class="d-flex flex-column justify-center flex-grow-1">
      <!-- Username -->
      <div class="username font-weight-bold" :class="{ 'font-size-14': small }">
        {{ post.user.username }}
      </div>

      <div
        class="date text--secondary font-size-12"
        :title="$utils.formatDatetime(post.created_at)"
      >
        <!-- Group -->
        <template v-if="post.group">
          <router-link :to="$utils.groupRoute(post.group)" class="btn-basic">
            {{ post.group.name }}
          </router-link>

          &nbsp;&bull;&nbsp;
        </template>

        <!-- Date -->
        <router-link
          :to="{ name: 'app.post', params: { id: post.id } }"
          :title="$utils.formatDatetime(post.created_at)"
          class="date text--secondary text-decoration-none-not-hover"
        >
          <datetime :value="post.created_at" fromNow />
        </router-link>

        <!-- Edited -->
        <span
          v-if="post.edited_at"
          :title="
            $t('common.editedAt', {
              date: $utils.formatDatetime(post.edited_at),
            })
          "
        >
          &nbsp;&bull;&nbsp;{{ $t("common.edited").toLowerCase() }}
        </span>
      </div>
    </div>

    <div v-if="!hideMenu">
      <PostMenu :post="post" />
    </div>
  </div>
</template>

<script>
import PostMenu from "./PostMenu.vue";
import UserHoverCard from "@/components/app/users/UserHoverCard.vue";

export default {
  components: { PostMenu, UserHoverCard },

  props: {
    post: Object,
    small: Boolean,
    hideMenu: Boolean,
  },

  computed: {
    avatarSize() {
      return this.small ? 40 : 50;
    },
  },
};
</script>

<style></style>
